<template>
  <section>
    <v-row>
      <v-col cols="6">
        <p class="text-h6 primary--text my-0">{{ solicitud.correlativo }}</p>
        <p class="text-h6 primary--text my-0">
          Solicitud de compra por convenio Marco
        </p>
        <p class="my-0">{{ solicitud.unidadSolicitante }}</p>
        <p class="my-0">Ejercicio 2022</p>
      </v-col>
      <v-col cols="12">
        <v-btn
          small
          elevation="0"
          class="px-0"
          style="color: green"
          @click="abrirDialogo('aprobar')"
          v-show="!conObservacion"
          v-if="solicitud.estado.id !== 2"
        >
          <v-icon color="green" small class="mr-2">mdi-send</v-icon>
          Aprobar solicitud
        </v-btn>
        <v-btn
          small
          elevation="0"
          class="px-0 mx-5"
          style="color: red"
          v-show="!conObservacion"
          v-if="solicitud.estado.id !== 2"
          @click="abrirDialogo('cancelar')"
        >
          <v-icon color="red" small class="mr-2">mdi-cancel</v-icon>
          Cancelar solicitud
        </v-btn>
        <v-btn small elevation="0" class="px-0" @click="conObservacion = true">
          <v-icon color="primary-text" small class="mr-2"
            >mdi-message-reply-text</v-icon
          >
          Marcar con observación
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      color="primary-text"
      class="mt-5"
      background-color="bgMinsal"
    >
      <v-tab v-for="item in items" :key="item.tab">
        <v-icon small class="mr-2">
          {{ item.icon }}
        </v-icon>
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="slide-y-transition"
        reverse-transition="fade-transition"
        v-for="item in items"
        :key="item.tab"
      >
        <v-card flat color="bgMinsal">
          <component :is="item.content" class="pa-7"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <div class="my-16">
      <router-link to="/jefe-area-aprobacion">Volver</router-link>
    </div>
    <confirmation-dialog
      :show="dialog"
      :title="dataDialog.titulo"
      :message="dataDialog.msj"
      btnConfirmar="Si"
      btnCancel="No"
      @close="close"
      @confirm="confirmSolicitud"
    />
  </section>
</template>

<script>
import { mapState } from "vuex";
import Insumo from "@/components/InsumoComponent";
import Proveedor from "@/components/ProveedorComponent.vue";
import AdminContrato from "@/components/AdministradorContratoComponent";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent";
export default {
  name: "detalleAprobacionSolicitudView",
  components: {
    Insumo,
    Proveedor,
    AdminContrato,
    ConfirmationDialog,
  },

  data() {
    return {
      tab: null,
      items: [],
      solicitud: {},
      conObservacion: false,
      dialog: false,
      dataDialog: { titulo: null, msj: null },
    };
  },

  computed: {
    ...mapState("solicitudCompraJefeArea", ["solicitudCompras"]),
  },

  methods: {
    abrirDialogo(accion) {
      switch (accion) {
        case "aprobar":
          this.dataDialog.titulo = "¿Desea aprobar la solicitud?";
          break;
        case "cancelar":
          this.dataDialog.titulo = "¿Desea cancelar la solicitud?";
          break;
      }
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    confirmSolicitud () {
        this.dialog = false;
    }
  },

  created() {
    this.solicitudCompras.forEach((item) => {
      if (item.id === this.$route.params.id) {
        this.solicitud = item;
        return;
      }
    });

    this.items = [
      { tab: "Insumos", content: "Insumo", icon: "mdi-package-variant-closed" },
      { tab: "Proveedores", content: "Proveedor", icon: "mdi-store-outline" },
      {
        tab: "Administradores de contrato",
        content: "AdminContrato",
        icon: "mdi-account-multiple",
      },
    ];
  },
};
</script>